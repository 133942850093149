// Accept.js
import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  VStack,
  Flex,
  Button,
  HStack,
  Heading,
  Icon,
  useToast,
  Tooltip,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Checkbox,
  Collapse,
  ScaleFade,
  Tag,
} from "@chakra-ui/react";
import {
  TbArrowUpRight,
  TbX,
  TbCheck,
  TbChevronDown,
} from "react-icons/tb";
import placeholderImage from "../../../assets/placeholder.jpg";
import axios from "axios";
import { useParams } from "react-router-dom";

import instagrampost from "../../../assets/instagrampost.png";
import instagramreel from "../../../assets/instagramreel.png";
import instagramstory from "../../../assets/instagramstory.png";
import tiktokIcon from "../../../assets/tiktok.png";

const extractDomain = (url) => {
  try {
    const hostname = new URL(url).hostname;
    return hostname.startsWith("www.") ? hostname.slice(4) : hostname;
  } catch (e) {
    return url;
  }
};

const getIconForDeliverable = (type) => {
  const lowerType = type.toLowerCase();
  if (lowerType.includes("post")) return instagrampost;
  if (lowerType.includes("story")) return instagramstory;
  if (lowerType.includes("reel")) return instagramreel;
  if (lowerType.includes("video")) return tiktokIcon;
  return instagrampost; // default icon
};

const Accept = ({ offerData }) => {
  const { workspaceId, campaignId, creatorId } = useParams();
  const { creator, campaignDetails } = offerData;
  const { offer, imageURL } = campaignDetails;
  const { deliverables: initialDeliverables, offer: initialOfferAmount } =
    creator;

  const existingOfferAmount =
    typeof initialOfferAmount === "number" && initialOfferAmount > 0
      ? initialOfferAmount
      : null;

  const [offerAmount] = useState(existingOfferAmount || 0);
  const [deliverables] = useState(initialDeliverables || []);
  const [imageSrc, setImageSrc] = useState(placeholderImage);

  const [emails, setEmails] = useState(creator.emails || []);
  const [emailInput, setEmailInput] = useState("");
  const [receiveMoreOffers, setReceiveMoreOffers] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isESigned, setIsESigned] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isAgreementOpen, setIsAgreementOpen] = useState(true);
  const toast = useToast();

  useEffect(() => {
    if (imageURL) {
      const img = new window.Image();
      img.onload = () => setImageSrc(imageURL);
      img.src = imageURL;
    }
  }, [imageURL]);

  useEffect(() => {
    // Validate email
    const emailRegex = /\S+@\S+\.\S+/;
    setIsEmailValid(emailRegex.test(emailInput));
  }, [emailInput]);

  const handleSaveAndSend = async () => {
    const emailsToSend = [...emails];
    if (isEmailValid && emailInput.trim() !== "") {
      emailsToSend.push(emailInput.trim());
    }

    if (emailsToSend.length === 0) {
      setEmailError(true);
      toast({
        title: "Email Required",
        description:
          "Please add at least one email before accepting the contract.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (!isESigned) {
      toast({
        title: "E-Signature Required",
        description: "Please review and sign the contract before proceeding.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);
    try {
      const payload = {
        workspaceId,
        campaignId,
        creatorId,
        offerData,
        emails: emailsToSend,
        receiveMoreOffers,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/campaigns-offer-contract-accept`,
        payload
      );

      if (response.status === 200 || response.status === 201) {
        toast({
          title: "Success",
          description: "Your contract has been accepted successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        window.location.reload();
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      console.error("Error accepting the contract:", error);
      toast({
        title: "Error",
        description:
          "There was an issue accepting the contract. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleESign = () => {
    setIsESigned(true);
    toast({
      title: "E-Signature Added",
      description: `You have signed the contract as ${creator.name}.`,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const handleAddEmail = () => {
    if (emailInput.trim() !== "" && isEmailValid) {
      setEmails([...emails, emailInput.trim()]);
      setEmailInput("");
      setEmailError(false);
    } else {
      setEmailError(true);
      toast({
        title: "Invalid Email",
        description: "Please enter a valid email address.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEmailInputChange = (e) => {
    const value = e.target.value;
    setEmailInput(value);
    setEmailError(false);
    // Validate email as the user types
    const emailRegex = /\S+@\S+\.\S+/;
    setIsEmailValid(emailRegex.test(value));
  };

  const handleEmailKeyPress = (e) => {
    if (e.key === "Enter") {
      handleAddEmail();
    }
  };

  const handleRemoveEmail = (emailToRemove) => {
    setEmails(emails.filter((email) => email !== emailToRemove));
  };

  let tooltipMessage = "";
  if (!isESigned && !isEmailValid && emails.length === 0) {
    tooltipMessage = "Please complete the agreement";
  } else if (!isESigned) {
    tooltipMessage = "Please complete the agreement";
  } else if (!isEmailValid && emails.length === 0) {
    tooltipMessage = "Please add a valid email";
  }

  // Style constants
  const bgColorOfferAmount = "#FBECC0";

  return (
    <Box
      position="relative"
      minH="100vh"
      bg="white"
      borderRadius="24px"
      overflow="hidden"
      maxW="sm"
      mx="auto"
    >
      {/* Removed the absolute positioning and placeholder for the status bar */}
      <Flex p={4} direction="column" align="center" mt="4">
        <Box width="100%">
          <VStack align="left" spacing={4}>
            <Text
              fontSize="14px"
              fontWeight="700"
              opacity={0.75}
              mt={4}
              textAlign="left"
            >
              Hi {creator?.name || "Creator"} 👋
            </Text>
            <Heading fontSize="28px" lineHeight="34px" textAlign="left">
              One last step before you'll start...
            </Heading>

            {/* Agreement Box */}
            <Box
              w="100%"
              p="12px"
              bg={bgColorOfferAmount}
              borderRadius="24px"
            >
              {/* Sign Agreement Header */}
              <HStack
                w="100%"
                alignItems="center"
                justifyContent="space-between"
                mb="8px"
                onClick={() => setIsAgreementOpen(!isAgreementOpen)}
                cursor="pointer"
              >
                <HStack spacing="8px">
                  <Button
                    p="10px"
                    bg="rgba(255, 255, 255, 0.70)"
                    borderRadius="8px"
                    _hover={{ bg: "rgba(255, 255, 255, 0.70)" }}
                  >
                    <Icon as={TbArrowUpRight} boxSize="20px" />
                  </Button>
                  <Text fontSize="16px" fontWeight="600">
                    Sign Agreement
                  </Text>
                </HStack>
                <Icon
                  as={TbChevronDown}
                  boxSize="20px"
                  color="#70757D"
                  transform={
                    isAgreementOpen ? "rotate(0deg)" : "rotate(-90deg)"
                  }
                  transition="transform 0.2s"
                />
              </HStack>

              {/* Agreement Content with Transition */}
              <Collapse in={isAgreementOpen} animateOpacity>
                <Box
                  w="100%"
                  maxH="245px"
                  overflowY="auto"
                  mb="8px"
                  pr="8px"
                  css={{
                    "&::-webkit-scrollbar": {
                      width: "8px",
                    },
                    "&::-webkit-scrollbar-track": {
                      background: "rgba(255, 255, 255, 0.70)",
                      borderRadius: "8px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: "rgba(0, 0, 0, 0.50)",
                      borderRadius: "20px",
                    },
                  }}
                >
                  <Text fontSize="14px" lineHeight="20px">
                    This agreement is made between{" "}
                    <strong>{creator.name}</strong> ("Creator") and{" "}
                    <strong>{offer?.companyName || "Company Name"}</strong>{" "}
                    ("Company") regarding the promotion of{" "}
                    <strong>{offer?.name || "Offer Name"}</strong> products
                    and services.
                    <br />
                    <br />
                    The Creator agrees to produce and publish the agreed
                    deliverables as outlined above in exchange for the offer
                    amount of{" "}
                    <strong>
                      ${offerAmount > 0 ? offerAmount.toLocaleString() : "—"}
                    </strong>
                    .
                    <br />
                    <br />
                    By signing below, both parties agree to the terms and
                    conditions of this agreement.
                  </Text>
                </Box>

                {/* E-Sign Agreement Button */}
                <Flex justifyContent="center" mt="16px">
                  <Button
                    w="202px"
                    h="40px"
                    bg="white"
                    boxShadow="0px 1px 4px rgba(10, 20, 25, 0.08)"
                    borderRadius="60px"
                    border="1px solid #C9CCD1"
                    onClick={handleESign}
                    _hover={{ bg: "white" }}
                    _active={{ bg: "white" }}
                    isDisabled={isESigned}
                  >
                    <HStack spacing="6px">
                      <Text fontSize="14px" fontWeight="500">
                        {isESigned ? "Signed" : "E-Sign Agreement"}
                      </Text>
                      <Icon as={TbArrowUpRight} boxSize="20px" />
                    </HStack>
                  </Button>
                </Flex>
              </Collapse>
            </Box>

            {/* Email Input and Checkbox */}
            <VStack w="100%" alignItems="flex-start" spacing="16px">
              <FormControl isInvalid={emailError}>
                <FormLabel fontSize="14px" fontWeight="500">
                  Your Email
                </FormLabel>
                <InputGroup>
                  <Input
                    value={emailInput}
                    onChange={handleEmailInputChange}
                    onKeyPress={handleEmailKeyPress}
                    placeholder="Enter your email"
                    bg="white"
                    border="1px solid #C9CCD1"
                    borderRadius="8px"
                  />
                  <InputRightElement
                    h="100%"
                    pointerEvents="none"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <ScaleFade
                      in={isEmailValid && emailInput}
                      initialScale={0.8}
                    >
                      <Icon as={TbCheck} color="green.500"   style={{ transform: 'translateY(3px)' }}
                      />
                    </ScaleFade>
                  </InputRightElement>
                </InputGroup>
                {emails.length > 0 && (
                  <HStack mt={2} spacing={2} wrap="wrap">
                    {emails.map((email, index) => (
                      <Tag
                        key={index}
                        size="md"
                        borderRadius="full"
                        variant="solid"
                        colorScheme="blue"
                      >
                        <Text mr={2}>{email}</Text>
                        <Icon
                          as={TbX}
                          cursor="pointer"
                          onClick={() => handleRemoveEmail(email)}
                        />
                      </Tag>
                    ))}
                  </HStack>
                )}
              </FormControl>

              <Checkbox
                isChecked={receiveMoreOffers}
                onChange={(e) => setReceiveMoreOffers(e.target.checked)}
              >
                <Text fontSize="14px" textTransform="lowercase">
                  I would like to receive more messages about offers from these
                  emails
                </Text>
              </Checkbox>
            </VStack>
          </VStack>
        </Box>
      </Flex>

      {/* Accept Contract Button */}
      <Box
        position="sticky"
        bottom="0"
        width="100%"
        p={4}
        textAlign="center"
        zIndex={1000}
      >
        <Tooltip
          label={tooltipMessage}
          isDisabled={tooltipMessage === ""}
          placement="top"
        >
          <Button
            bg="black"
            color="white"
            size="lg"
            h="60px"
            borderRadius="full"
            fontSize="18px"
            maxW="sm"
            w="100%"
            onClick={handleSaveAndSend}
            isLoading={isLoading}
            loadingText="Accepting..."
            isDisabled={!isESigned || (!isEmailValid && emails.length === 0)}
            _hover={{ bg: "black" }}
            _disabled={{
              bg: "#bee1e5",
              opacity: 1,
              cursor: "not-allowed",
              _hover: {
                bg: "#bee1e5",
              },
            }}
          >
            Save and Send
          </Button>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default Accept;
