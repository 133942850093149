import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  VStack,
  Heading,
  Link,
  Button,
} from '@chakra-ui/react';
import ConfettiExplosion from 'react-confetti-explosion';

const Completed = ({ offerData }) => {
  const { creator } = offerData;
  const [isExploding, setIsExploding] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsExploding(true);
  }, []);

  return (
    <Box
      mx="auto"
      mt={10}
      position="relative"
      mb={10}
      px={4}
      maxW={{ base: '100%', md: '600px' }}
    >
      {isExploding && (
        <Box
          position="fixed"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
        >
          <ConfettiExplosion particleCount={200} duration={3000} />
        </Box>
      )}
      <VStack align="start" spacing={4}>
        <Heading
          fontSize="14px"
          fontWeight="700"
          color="black"
          opacity={0.75}
          fontFamily="Poppins"
          textAlign="center"
          mt={4}
        >
          Hi, {creator?.name || 'Creator'} 👋
        </Heading>
        <Heading
          fontSize="28px"
          fontWeight="700"
          color="black"
          fontFamily="Poppins"
          lineHeight="34px"
        >
          congratulations!
        </Heading>
        <Text
          fontSize="16px"
          fontFamily="Poppins"
          lineHeight="24px"
          color="#51555C"
          fontWeight="400"
        >
          You successfully completed the campaign
          <br />
          <Text as="span" color="black" fontWeight="600">
            You will be paid
          </Text>
          <Text as="span" color="#51555C" fontWeight="400">
            {' '}out in{' '}
          </Text>
          <Text as="span" color="black" fontWeight="600">
            10-30
          </Text>
          <Text as="span" color="#51555C" fontWeight="400">
            {' '}days! Thank you for being awesome and contributing to the success of our campaign!
          </Text>
        </Text>
      </VStack>
      {/* Collect Payout Button */}
      <Box mt={10} textAlign="center">
        <Link href="https://hoo.be" isExternal _hover={{ textDecoration: 'none' }}>
          <Button
            colorScheme="black"
            size="lg"
            borderRadius="full"
            px={8}
            py={6}
            fontSize="18px"
            fontWeight="500"
            fontFamily="Poppins"
            bg="black"
            color="white"
            _hover={{ bg: 'gray.800' }}
            boxShadow="0px 2px 8px -1px rgba(10, 20, 25, 0.08)"
          >
            view more offers
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default Completed;
