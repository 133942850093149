// Countered.js
import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  VStack,
  Flex,
  HStack,
  Heading,
  Image,
  Link,
  Icon,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { TbArrowUpRight } from "react-icons/tb";
import Deliverables from "../Deliverables";
import placeholderImage from "../../../assets/placeholder.jpg";

import instagrampost from "../../../assets/instagrampost.png";
import instagramreel from "../../../assets/instagramreel.png";
import instagramstory from "../../../assets/instagramstory.png";
import tiktokIcon from "../../../assets/tiktok.png";

const extractDomain = (url) => {
  try {
    const hostname = new URL(url).hostname;
    return hostname.startsWith("www.") ? hostname.slice(4) : hostname;
  } catch (e) {
    return url;
  }
};

const getIconForDeliverable = (type) => {
  const lowerType = type.toLowerCase();
  if (lowerType.includes("post")) return instagrampost;
  if (lowerType.includes("story")) return instagramstory;
  if (lowerType.includes("reel")) return instagramreel;
  if (lowerType.includes("video")) return tiktokIcon;
  return instagrampost; // default icon
};

const getLatestTimeline = (timeline) => {
  return timeline.reduce((latest, current) => {
    return new Date(current.timestamp) > new Date(latest.timestamp)
      ? current
      : latest;
  });
};

const Countered = ({ offerData }) => {
  const { creator, campaignDetails } = offerData;
  const { offer, imageURL } = campaignDetails;

  const latestTimeline = getLatestTimeline(creator.timeline);
  const { deliverables, offerAmount } = latestTimeline;

  const [imageSrc, setImageSrc] = useState(placeholderImage);

  useEffect(() => {
    if (imageURL) {
      const img = new window.Image();
      img.onload = () => setImageSrc(imageURL);
      img.src = imageURL;
    }
  }, [imageURL]);

  // Style constants
  const bgColorCampaignInfo = "#D5D1FA";
  const bgColorOfferAmount = "#FBECC0";
  const borderRadius = "24px";

  return (
    <Box position="relative" minH="100vh" bg="white" borderRadius="24px">
      <Flex p={4} direction="column" align="center">
        <Box maxW="375px" width="100%">
          <VStack align="start" spacing={4}>
            <Text
              fontSize="14px"
              fontWeight="700"
              opacity={0.75}
              mt={4}
              ml={1}
            >
              Hi {creator?.name || "Creator"} 👋
            </Text>
            <Heading fontSize="28px" lineHeight="34px">
              We've received your offer
            </Heading>
            <Text fontSize="16px" mt={2} ml={1}>
              We'll get back to you with a decision on your offer very soon
            </Text>

            <Grid
              templateColumns="repeat(2, 1fr)"
              templateRows="repeat(2, auto)"
              gap={2}
              w="100%"
            >
              <GridItem rowSpan={2} colSpan={1}>
                <Link
                  href={offer?.website || "#"}
                  isExternal
                  _hover={{ textDecoration: "none" }}
                >
                  <Box
                    borderRadius={borderRadius}
                    p={4}
                    bg={bgColorCampaignInfo}
                    height="100%"
                    position="relative"
                  >
                    <VStack
                      align="start"
                      spacing={4}
                      height="100%"
                      justifyContent="space-between"
                    >
                      <HStack justifyContent="space-between" w="100%">
                        <Image
                          src={imageSrc}
                          alt="Campaign Image"
                          borderRadius="full"
                          objectFit="cover"
                          boxSize="40px"
                          bg="white"
                          border="2px solid white"
                        />
                        <Icon as={TbArrowUpRight} boxSize="24px" />
                      </HStack>
                      <Heading
                        fontSize="24px"
                        fontWeight="bold"
                        textAlign="left"
                      >
                        {offer?.name || "Offer Name"}
                      </Heading>
                      <Box w="100%">
                        <Text
                          fontSize="16px"
                          color="black"
                          textAlign="left"
                        >
                          Hosted by <br></br> {campaignDetails.name || "Company Name"}
                        </Text>
                      </Box>
                    </VStack>
                  </Box>
                </Link>
              </GridItem>

              <GridItem colSpan={1}>
                <Box
                  borderRadius={borderRadius}
                  p={4}
                  bg={bgColorOfferAmount}
                  minH="98px"
                  position="relative"
                >
                  <VStack align="start" spacing={2}>
                    <Text fontSize="16px">Offer Amount</Text>
                    <HStack spacing={2}>
                      <Text fontSize="36px">💸</Text>
                      <Text fontSize="16px" fontWeight="700">
                        $
                        {offerAmount > 0
                          ? offerAmount.toLocaleString()
                          : "—"}
                      </Text>
                    </HStack>
                  </VStack>
                </Box>
              </GridItem>

              <GridItem colSpan={1}>
                <Box
                  borderRadius={borderRadius}
                  p={4}
                  border="1px solid #C9CCD1"
                  minH="140px"
                  position="relative"
                >
                  <VStack align="start" spacing={2}>
                    <Text fontSize="16px">Deliverables</Text>
                    <Box>
                      {deliverables && deliverables.length > 0 ? (
                        deliverables.map((item, index) => {
                          const displayType = item.type
                            .replace("Instagram ", "")
                            .replace("TikTok ", "");
                          const iconSrc = getIconForDeliverable(displayType);
                          return (
                            <HStack
                              key={index}
                              spacing={2}
                              alignItems="center"
                              mt={2}
                            >
                              <Image
                                src={iconSrc}
                                boxSize="24px"
                                alt={displayType}
                              />
                              <Text fontSize="16px" fontWeight="700">
                                {item.count} {displayType}
                                {item.count > 1 ? "s" : ""}
                              </Text>
                            </HStack>
                          );
                        })
                      ) : (
                        <Text>No deliverables specified.</Text>
                      )}
                    </Box>
                  </VStack>
                </Box>
              </GridItem>
            </Grid>

            {/* Campaign Description */}
            <Box mt={4} p={4}>
              <Text fontSize="14px" fontWeight="700" opacity={0.75}>
                About Offer
              </Text>
              <Text fontSize="14px" mt={2}>
                {offer?.description || "No description provided."}
              </Text>
            </Box>
          </VStack>
        </Box>
      </Flex>
    </Box>
  );
};

export default Countered;
