import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Text,
  HStack,
  Icon,
  Input,
  FormLabel,
  useToast,
  FormControl,
  FormErrorMessage,
  Image,
  VStack,
  Stack,
  Progress,
} from "@chakra-ui/react";
import { TbArrowLeft, TbPlus, TbCheck, TbScan } from "react-icons/tb";
import { useParams } from "react-router-dom";
import axios from "axios";

import instagramIcon from "../../../assets/instagrampost.png";
import instagramReel from "../../../assets/instagramreel.png";
import instagramStory from "../../../assets/instagramstory.png";
import tiktokPostIcon from "../../../assets/tiktok.png";

import TrackingVideos from "./TrackingVideos";

const TrackingScan = ({ onBack, offerData }) => {
  const toast = useToast();
  const { workspaceId, campaignId, creatorId } = useParams();
  const { creator } = offerData;
  const { deliverables } = creator;

  const [trackingData, setTrackingData] = useState([]);
  const [isScanLoading, setIsScanLoading] = useState(false);
  const [newMediaLink, setNewMediaLink] = useState("");
  const [isLinkValid, setIsLinkValid] = useState(true);
  const [isMediaLinkAdding, setIsMediaLinkAdding] = useState(false);
  const [deliverableProgressData, setDeliverableProgressData] = useState([]);
  const [isCampaignComplete, setIsCampaignComplete] = useState(false);

  const regexPatterns = {
    instagramPost: /(https?:\/\/)?(www\.)?instagram\.com\/p\/[A-Za-z0-9_-]+\/?/,
    instagramReel:
      /(https?:\/\/)?(www\.)?instagram\.com\/reel(s)?\/[A-Za-z0-9_-]+\/?/,
    instagramStory:
      /(https?:\/\/)?(www\.)?instagram\.com\/stories\/[^/]+(?:\/\d+)?\/?/,
    tiktokVideo: /(https?:\/\/)?(www\.)?tiktok\.com\/@[^/]*\/video\/\d+\/?/,
  };

  const getIconForDeliverable = (deliverableType) => {
    const iconMapping = {
      "Instagram Story": instagramStory,
      "Instagram Reel": instagramReel,
      "Instagram Post": instagramIcon,
      "TikTok Video": tiktokPostIcon,
    };
    return iconMapping[deliverableType] || null;
  };

  const validateMediaLink = (link) => {
    return Object.values(regexPatterns).some((pattern) => pattern.test(link));
  };

  const handleMediaLinkChange = (e) => {
    const link = e.target.value;
    setNewMediaLink(link);
    setIsLinkValid(validateMediaLink(link));
  };

  const handleAddMediaLink = async () => {
    const link = newMediaLink.trim();
    if (!link || !isLinkValid) return;

    setIsMediaLinkAdding(true);
    try {
      const response = await axios.post(
        "https://hoobe-tracking.onrender.com/posts",
        {
          urls: [link],
        }
      );

      const updatedData = response.data.map((item) => ({
        ...item,
        logged: false,
      }));

      // Filter out duplicates
      const existingPostIds = new Set(trackingData.map((post) => post.id));
      const newData = updatedData.filter(
        (item) => !existingPostIds.has(item.id)
      );

      setTrackingData((prevData) => {
        if (prevData && Array.isArray(prevData)) {
          return [...prevData, ...newData];
        } else {
          return newData;
        }
      });

      toast({
        title: "Media Link Added",
        description: "Your media link has been successfully added.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      setNewMediaLink("");
      setIsLinkValid(true);
    } catch (error) {
      console.error("Error adding media link:", error);
      toast({
        title: "Error",
        description: "Failed to add media link.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsMediaLinkAdding(false);
    }
  };

  const handleScanProfile = async () => {
    setIsScanLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/campaigns-offer-tracking-fetch`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            workspaceId,
            campaignId,
            creatorId,
            offerData,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch tracking data");
      }

      const data = await response.json();

      // Filter out already existing posts to avoid duplication
      const existingPostIds = new Set(trackingData.map((post) => post.id));

      const newData = data.filter(
        (item) => !existingPostIds.has(item.id)
      );

      const updatedData = newData.map((item) => ({
        ...item,
        logged: false,
      }));

      setTrackingData((prevData) => {
        if (prevData && Array.isArray(prevData)) {
          return [...prevData, ...updatedData];
        } else {
          return updatedData;
        }
      });

      toast({
        title: "Profile Scanned",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      console.error("Error scanning profile:", error);
      toast({
        title: "Error",
        description: error.message || "Failed to scan profile.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    } finally {
      setIsScanLoading(false);
    }
  };

  useEffect(() => {
    if (offerData && offerData.posts) {
      const updatedData = offerData.posts.map((item) => ({
        ...item,
        logged: true,
      }));
      setTrackingData(updatedData);
    }
  }, [offerData]);

  useEffect(() => {
    if (!deliverables) return;

    const deliverableTypeToPostInfo = {
      "Instagram Story": { platform: "instagram", type: "story" },
      "Instagram Reel": { platform: "instagram", type: "reel" },
      "Instagram Post": { platform: "instagram", type: "post" },
      "TikTok Video": { platform: "tiktok", type: "post" },
    };

    const loggedPosts =
      trackingData?.filter((post) => post.logged === true) || [];

    const progressData = deliverables.map((deliverable) => {
      const mapping = deliverableTypeToPostInfo[deliverable.type] || {};
      const { platform, type } = mapping;

      if (!platform || !type) {
        return {
          ...deliverable,
          actualCount: 0,
          progress: 0,
        };
      }

      const matchingPosts = loggedPosts.filter(
        (post) => post.platform === platform && post.type === type
      );

      const actualCount = matchingPosts.length;
      const requiredCount = deliverable.count;

      const progress = Math.min((actualCount / requiredCount) * 100, 100);

      return {
        ...deliverable,
        actualCount,
        progress,
      };
    });

    setDeliverableProgressData(progressData);

    const allComplete = progressData.every((d) => d.progress >= 100);
    setIsCampaignComplete(allComplete);
  }, [trackingData, deliverables]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleUpdateTrackingDataItem = (index, updatedItem) => {
    setTrackingData((prevData) => {
      const newData = [...prevData];
      newData[index] = updatedItem;
      return newData;
    });
  };

  const handleCompleteCampaign = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/campaigns-offer-complete`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ workspaceId, campaignId, creatorId }),
        }
      );

      if (response.ok) {
        toast({
          title: "Campaign completed successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        window.location.reload();
      } else {
        const errorData = await response.json();
        console.error("Failed to complete the campaign:", errorData);

        toast({
          title: "Failed to complete the campaign",
          description: errorData.message || "Server error",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("An error occurred while completing the campaign:", error);

      toast({
        title: "An error occurred",
        description: "Unable to complete the campaign.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box minH="100vh" p={4} position="relative" bg="white">
      <Box maxW="600px" mx="auto">
        <Button
          leftIcon={<TbArrowLeft />}
          onClick={onBack}
          mb={4}
          variant="outline"
        >
          Back To Guidelines
        </Button>

        <Box mt={4}>
          <Text fontSize="28px" fontWeight="700" fontFamily="Poppins">
            Now it’s time to post!
          </Text>

          <Stack spacing={4} mt={6}>
            {deliverableProgressData.map((deliverable, index) => {
              const isCompleted = deliverable.progress >= 100;
              return (
                <Box
                  key={index}
                  p={4}
                  borderRadius="16px"
                  borderWidth="1px"
                  bg={isCompleted ? "#DFF5E8" : "white"}
                  borderColor={isCompleted ? "#449969" : "#C9CCD1"}
                >
                  <HStack align="center" spacing={4}>
                    <Box
                      w="40px"
                      h="40px"
                      bg="#D5D1FA"
                      borderRadius="full"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Image
                        boxSize="24px"
                        src={getIconForDeliverable(deliverable.type)}
                        alt={deliverable.type}
                      />
                    </Box>
                    <VStack align="start" spacing={1} flex="1">
                      <Text
                        fontSize="16px"
                        fontWeight="600"
                        fontFamily="Poppins"
                      >
                        {deliverable.type}
                      </Text>
                      <Text
                        fontSize="14px"
                        color="black"
                        fontFamily="Poppins"
                      >
                        {deliverable.actualCount}/{deliverable.count} completed
                      </Text>
                    </VStack>
                    {isCompleted ? (
                      <Icon
                        as={TbCheck}
                        boxSize="24px"
                        color="#449969"
                        mr={2}
                      />
                    ) : (
                      <HStack spacing={3} alignItems="center">
                        <Progress
                          value={deliverable.progress}
                          size="sm"
                          colorScheme="blackAlpha"
                          w="100px"
                          borderRadius="full"
                        />
                        <Text
                          fontSize="16px"
                          fontWeight="600"
                          fontFamily="Poppins"
                        >
                          {Math.round(deliverable.progress)}%
                        </Text>
                      </HStack>
                    )}
                  </HStack>
                </Box>
              );
            })}
          </Stack>
        </Box>

        {trackingData && trackingData.length > 0 && (
          <TrackingVideos
            trackingData={trackingData}
            onUpdateTrackingDataItem={handleUpdateTrackingDataItem}
          />
        )}

        <Box mt={8} mb={8} maxW="600px" mx="auto">
          <FormControl isInvalid={!isLinkValid && newMediaLink !== ""}>
            <FormLabel
              htmlFor="mediaLink"
              fontWeight="bold"
              mb={2}
              fontFamily="Poppins"
              fontSize="16px"
            >
              Don't see your post? Add your media link below:
            </FormLabel>
            <HStack>
              <Input
                id="mediaLink"
                placeholder="Enter media link"
                size="lg"
                borderRadius="xl"
                value={newMediaLink}
                onChange={handleMediaLinkChange}
                isInvalid={!isLinkValid && newMediaLink !== ""}
                fontFamily="Poppins"
              />
              <Button
                leftIcon={<TbPlus />}
                onClick={handleAddMediaLink}
                isLoading={isMediaLinkAdding}
                loadingText="Adding"
                size="lg"
                borderRadius="xl"
                colorScheme="blue"
                isDisabled={!isLinkValid || newMediaLink.trim() === ""}
                fontFamily="Poppins"
              >
                Add
              </Button>
            </HStack>
            {!isLinkValid && newMediaLink !== "" && (
              <FormErrorMessage fontFamily="Poppins">
                Please enter a valid Instagram or TikTok URL.
              </FormErrorMessage>
            )}
          </FormControl>
        </Box>
      </Box>

      <Box
        position="sticky"
        bottom="0"
        width="100%"
        p={4}
        textAlign="center"
        zIndex={1000}
        bg="white"
      >
        {isCampaignComplete ? (
          <Button
            onClick={handleCompleteCampaign}
            colorScheme="blue"
            size="lg"
            h="60px"
            w="full"
            borderRadius="full"
            fontSize="18px"
            maxW="600px"
            fontFamily="Poppins"
          >
            Complete Campaign
          </Button>
        ) : (
          <Button
            leftIcon={<TbScan />}
            size="lg"
            bg="black"
            color="white"
            borderRadius="full"
            onClick={handleScanProfile}
            isLoading={isScanLoading}
            loadingText="Scanning"
            h="60px"
            w="full"
            fontSize="18px"
            maxW="600px"
            fontFamily="Poppins"
            _hover={{ bg: "black" }}
          >
            Scan My Profile
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default TrackingScan;
