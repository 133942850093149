// Footer.js
import React from 'react';
import { Flex, Image, Text, Box } from '@chakra-ui/react';
import icon from '../../assets/logo.png'; // Adjust the path to your icon

const Footer = () => {
  return (
    <Box bg="gray.300" py={6}>
      <Flex
        maxW="1200px"
        mx="auto"
        borderRadius="md"
        p={4}
        alignItems="center"
        justifyContent="center"
        direction={{ base: 'column', md: 'row' }}
      >
        <Image src={icon} alt="hoo.be logo" boxSize="40px" mb={{ base: 4, md: 0 }} mr={{ md: 3 }} />
        <Text fontSize="md" textAlign="center">
          &copy; {new Date().getFullYear()} hoo.be. All rights reserved.
        </Text>
      </Flex>
    </Box>
  );
};

export default Footer;
