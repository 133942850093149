import React from "react";
import {
  Box,
  Text,
  Button,
  useToast,
  Flex,
  Link,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { TbHeart, TbPlus } from "react-icons/tb";


const TrackingVideos = ({ trackingData, onUpdateTrackingDataItem }) => {
  const toast = useToast();
  const { workspaceId, campaignId, creatorId } = useParams();

  const getDeliverableType = (platform, type) => {
    if (platform === "instagram") {
      if (type === "post") {
        return "Instagram Post";
      } else if (type === "reel") {
        return "Instagram Reel";
      } else if (type === "story") {
        return "Instagram Story";
      }
    } else if (platform === "tiktok") {
      if (type === "post") {
        return "TikTok Video";
      }
    }
    return null;
  };

  const handleLogPost = async (index) => {
    const item = trackingData[index];

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/campaigns-offer-tracking-log`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            workspaceId,
            campaignId,
            creatorId,
            postInfo: item,
          }),
        }
      );

      if (response.ok) {
        const updatedItem = { ...item, logged: true };
        onUpdateTrackingDataItem(index, updatedItem);

        toast({
          title: "Successfully added post",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      } else {
        console.error("Failed to log the post.");
        toast({
          title: "Failed to log the post.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      }
    } catch (error) {
      console.error("An error occurred while logging the post:", error);
      toast({
        title: "An error occurred while logging the post.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  };

  // Prepare data
  const unloggedPosts = trackingData
    .map((post, index) => ({ post, index }))
    .filter(({ post }) => !post.logged);

  const loggedPosts = trackingData
    .map((post, index) => ({ post, index }))
    .filter(({ post }) => post.logged);

  // Group logged posts by deliverable type
  const deliverablePosts = {};

  loggedPosts.forEach(({ post, index }) => {
    const deliverableType = getDeliverableType(post.platform, post.type);
    if (!deliverableType) return;

    if (!deliverablePosts[deliverableType]) {
      deliverablePosts[deliverableType] = [];
    }

    deliverablePosts[deliverableType].push({ post, index });
  });

  return (
    <Box>
      {/* Unlogged Posts */}
      {unloggedPosts.length > 0 && (
        <Box mt={8}>
          <Text fontSize="20px" fontWeight="600" fontFamily="Poppins" mb={4}>
            Here's what we found:
          </Text>
          <Box
            mt={4}
            p="16px"
            bg="#F4F5F7"
            borderRadius="24px"
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap="16px"
          >
            {unloggedPosts.map(({ post, index }) => {
              // Adjust card size based on type
              const isSquare =
                post.platform === "instagram" && post.type === "post";
              const cardWidth = isSquare ? "161px" : "150.79px";
              const cardHeight = isSquare ? "161px" : "268.66px";

              return (
                <Box
                  key={index}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Link href={post.url} isExternal>
                    <Box
                      width={cardWidth}
                      height={cardHeight}
                      borderRadius="14.81px"
                      overflow="hidden"
                      bgImage={`url(${post.thumbnail_url})`}
                      bgSize="cover"
                      bgPosition="center"
                      position="relative"
                    >
                      {/* Overlay */}
                      <Box
                        position="absolute"
                        bottom="0"
                        width="100%"
                        p="6.93px"
                        bg="rgba(0, 0, 0, 0.0)"
                        display="flex"
                        alignItems="center"
                        gap="6.93px"
                      >
                        <TbHeart color="white" size="20px" />
                        <Text
                          flex="1"
                          color="white"
                          fontSize="12.13px"
                          fontFamily="Poppins"
                          fontWeight="500"
                          lineHeight="17.33px"
                          wordBreak="break-word"
                        >
                          {post.likes || "0"}
                        </Text>
                      </Box>
                    </Box>
                  </Link>
                  {/* Add Content Button */}
                  <Button
                    mt={4}
                    width="141px"
                    paddingLeft="16px"
                    paddingRight="16px"
                    paddingTop="10px"
                    paddingBottom="10px"
                    bg="white"
                    boxShadow="0px 1px 4px rgba(10, 20, 25, 0.08)"
                    borderRadius="60px"
                    border="1px solid #C9CCD1"
                    display="flex"
                    alignItems="center"
                    gap="6px"
                    onClick={() => handleLogPost(index)}
                  >
                    <TbPlus size="20px" color="black" />
                    <Text
                      textAlign="center"
                      color="black"
                      fontSize="14px"
                      fontFamily="Poppins"
                      fontWeight="500"
                      lineHeight="20px"
                      wordBreak="break-word"
                    >
                      Add Content
                    </Text>
                  </Button>
                </Box>
              );
            })}
          </Box>
        </Box>
      )}

      {/* Logged Posts */}
      {Object.keys(deliverablePosts).map((deliverableType) => (
        <Box key={deliverableType} mt={8}>
          {/* Section Title */}
          <Text
            fontSize="20px"
            fontWeight="600"
            fontFamily="Poppins"
            mb={4}
          >
            {`Your ${deliverableType}`}
          </Text>

          <Box overflow="hidden" position="relative">
            <Flex overflowX="auto" gap={4}>
              {deliverablePosts[deliverableType].map(({ post, index }) => {
                // Adjust card size based on type
                const isSquare =
                  post.platform === "instagram" && post.type === "post";
                const cardWidth = isSquare ? "161px" : "161px";
                const cardHeight = isSquare ? "161px" : "287px";

                return (
                  <Link href={post.url} isExternal key={index}>
                    <Box
                      width={cardWidth}
                      minW={cardWidth}
                      height={cardHeight}
                      borderRadius="14.81px"
                      overflow="hidden"
                      flexShrink={0}
                      bgImage={`url(${post.thumbnail_url})`}
                      bgSize="cover"
                      bgPosition="center"
                      position="relative"
                    >
                      {/* Overlay */}
                      <Box
                        position="absolute"
                        bottom="0"
                        width="100%"
                        p="7.41px"
                        bg="rgba(0, 0, 0, 0.0)"
                        display="flex"
                        alignItems="center"
                        gap="7.41px"
                      >
                        <TbHeart color="white" size="22px" />
                        <Text
                          flex="1"
                          color="white"
                          fontSize="12.96px"
                          fontFamily="Poppins"
                          fontWeight="500"
                          lineHeight="18.52px"
                          wordBreak="break-word"
                        >
                          {post.likes || "N/A"}
                        </Text>
                      </Box>
                    </Box>
                  </Link>
                );
              })}
            </Flex>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default TrackingVideos;
