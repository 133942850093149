// Contacted.js
import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  VStack,
  Flex,
  Button,
  HStack,
  Tag,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  useDisclosure,
  Heading,
  Image,
  Link,
  Icon,
  Grid,
  GridItem,
  useToast,
} from "@chakra-ui/react";
import {
  TbPlus,
  TbArrowUpRight,
  TbPencil,
} from "react-icons/tb";

import Deliverables from "../Deliverables";
import placeholderImage from "../../../assets/placeholder.jpg";
import { useParams } from "react-router-dom";

import instagrampost from "../../../assets/instagrampost.png";
import instagramreel from "../../../assets/instagramreel.png";
import instagramstory from "../../../assets/instagramstory.png";
import tiktokIcon from "../../../assets/tiktok.png";

const extractDomain = (url) => {
  try {
    const hostname = new URL(url).hostname;
    return hostname.startsWith("www.") ? hostname.slice(4) : hostname;
  } catch (e) {
    return url;
  }
};

const getIconForDeliverable = (type) => {
  const lowerType = type.toLowerCase();
  if (lowerType.includes("post")) return instagrampost;
  if (lowerType.includes("story")) return instagramstory;
  if (lowerType.includes("reel")) return instagramreel;
  if (lowerType.includes("video")) return tiktokIcon;
  return instagrampost; // default icon
};

const Contacted = ({ offerData, fetchOfferData }) => {
  const { workspaceId, campaignId, creatorId } = useParams();
  const toast = useToast();

  const { creator, campaignDetails } = offerData;
  const { offer, imageURL } = campaignDetails;
  const { deliverables: initialDeliverables, offer: initialOfferAmount } =
    creator;

  const existingOfferAmount =
    typeof initialOfferAmount === "number" && initialOfferAmount > 0
      ? initialOfferAmount
      : null;

  const [offerAmount, setOfferAmount] = useState(existingOfferAmount || 0);
  const [deliverables, setDeliverables] = useState(initialDeliverables || []);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [imageSrc, setImageSrc] = useState(placeholderImage);

  const [hasChanges, setHasChanges] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isDataChanged =
    (existingOfferAmount !== null && offerAmount !== existingOfferAmount) ||
    JSON.stringify(deliverables) !== JSON.stringify(initialDeliverables || []);

  useEffect(() => {
    if (imageURL) {
      const img = new window.Image();
      img.onload = () => setImageSrc(imageURL);
      img.src = imageURL;
    }
  }, [imageURL]);

  useEffect(() => {
    setHasChanges(isDataChanged);
  }, [isDataChanged]);

  const handleButtonClick = async () => {
    if (existingOfferAmount !== null && !hasChanges) {
      // Accept Offer
      try {
        setIsLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_HOOBE_API_URL}/campaigns-offer-accept`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              workspaceId,
              campaignId,
              creatorId,
              offerAmount: existingOfferAmount,
              deliverables: deliverables,
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        console.log(data);

        toast({
          title: "Offer Accepted",
          description: `You have accepted the offer of $${existingOfferAmount.toFixed(
            2
          )}.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        fetchOfferData();
      } catch (error) {
        console.error("Error accepting offer:", error);
        toast({
          title: "Error",
          description: "There was an error accepting the offer.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    } else if (hasChanges) {
      // Submit Counter Offer
      if (offerAmount <= 0) {
        toast({
          title: "Invalid Offer",
          description: "Please select an offer amount greater than 0.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      if (deliverables.length === 0) {
        toast({
          title: "No Deliverables",
          description: "Please add at least one deliverable.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      try {
        setIsLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_HOOBE_API_URL}/campaigns-offer-counter`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              workspaceId,
              campaignId,
              creatorId,
              offerAmount: offerAmount,
              deliverables: deliverables,
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        console.log(data);

        toast({
          title: "Offer Submitted",
          description: `You have submitted an offer of $${offerAmount.toFixed(
            2
          )}.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        fetchOfferData();

        setHasChanges(false);
      } catch (error) {
        console.error("Error submitting offer:", error);
        toast({
          title: "Error",
          description: "There was an error submitting your offer.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      // Edit Offer
      onOpen();
    }
  };

  const isButtonDisabled = offerAmount <= 0 || deliverables.length === 0;

  // New style constants based on your Figma code
  const bgColorCampaignInfo = "#D5D1FA";
  const bgColorOfferAmount = "#FBECC0";
  const borderRadius = "24px";

  return (
    <Box position="relative" minH="100vh" bg="white" borderRadius="24px">
      <Flex p={4} direction="column" align="center">
        <Box maxW="375px" width="100%">
          <VStack align="start" spacing={4}>
            <Text
              fontSize="14px"
              fontWeight="700"
              opacity={0.75}
              mt={4}
              ml={1}
            >
              Hi {creator?.name || "Creator"} 👋
            </Text>
            <Heading fontSize="28px" lineHeight="34px">
              You've been invited
              <br />
              to collaborate
            </Heading>

            <Grid
              templateColumns="repeat(2, 1fr)"
              templateRows="repeat(2, auto)"
              gap={2}
              w="100%"
            >
              <GridItem rowSpan={2} colSpan={1}>
                <Link
                  href={offer?.website || "#"}
                  isExternal
                  _hover={{ textDecoration: "none" }}
                >
                  <Box
                    borderRadius={borderRadius}
                    p={4}
                    bg={bgColorCampaignInfo}
                    height="100%"
                    position="relative"
                  >
                    <VStack
                      align="start"
                      spacing={4}
                      height="100%"
                      justifyContent="space-between"
                    >
                      <HStack justifyContent="space-between" w="100%">
                        <Image
                          src={imageSrc}
                          alt="Campaign Image"
                          borderRadius="full"
                          objectFit="cover"
                          boxSize="40px"
                          bg="white"
                          border="2px solid white"
                        />
                        <Icon as={TbArrowUpRight} boxSize="24px" />
                      </HStack>
                      <Heading
                        fontSize="24px"
                        fontWeight="bold"
                        textAlign="left"
                      >
                        {offer?.name || "Offer Name"}
                      </Heading>
                      <Box w="100%">
                        <Text
                          fontSize="16px"
                          color="black"
                          textAlign="left"
                        >
                          Hosted by <br></br> {campaignDetails.name || "Company Name"}
                        </Text>
                      </Box>
                    </VStack>
                  </Box>
                </Link>
              </GridItem>

              <GridItem colSpan={1}>
                <Box
                  borderRadius={borderRadius}
                  p={4}
                  bg={bgColorOfferAmount}
                  minH="98px"
                  cursor="pointer"
                  onClick={onOpen}
                  position="relative"
                >
                  <VStack align="start" spacing={2}>
                    <Text fontSize="16px">Offer Amount</Text>
                    <HStack spacing={2}>
                      <Text fontSize="36px">💸</Text>
                      <Text fontSize="16px" fontWeight="700">
                        $
                        {offerAmount > 0
                          ? offerAmount.toLocaleString()
                          : existingOfferAmount !== null
                          ? existingOfferAmount.toLocaleString()
                          : "—"}
                      </Text>
                    </HStack>
                  </VStack>
                  <Icon
                    as={TbPencil}
                    boxSize="16px"
                    position="absolute"
                    bottom="8px"
                    right="8px"
                    color="gray.500"
                  />
                </Box>
              </GridItem>

              <GridItem colSpan={1}>
                <Box
                  borderRadius={borderRadius}
                  p={4}
                  border="1px solid #C9CCD1"
                  minH="140px"
                  cursor="pointer"
                  onClick={onOpen}
                  position="relative"
                >
                  <VStack align="start" spacing={2}>
                    <Text fontSize="16px">Deliverables</Text>
                    <Box>
                      {deliverables && deliverables.length > 0 ? (
                        deliverables.map((item, index) => {
                          const displayType = item.type
                            .replace("Instagram ", "")
                            .replace("TikTok ", "");
                          const iconSrc = getIconForDeliverable(displayType);
                          return (
                            <HStack
                              key={index}
                              spacing={2}
                              alignItems="center"
                              mt={2}
                            >
                              <Image
                                src={iconSrc}
                                boxSize="24px"
                                alt={displayType}
                              />
                              <Text fontSize="16px" fontWeight="700">
                                {item.count} {displayType}
                                {item.count > 1 ? "s" : ""}
                              </Text>
                            </HStack>
                          );
                        })
                      ) : (
                        <Tag
                          size="md"
                          px={2}
                          py={2}
                          bg="transparent"
                          display="inline-flex"
                          alignItems="center"
                          justifyContent="center"
                          aria-label="Add Deliverable"
                        >
                          <Icon as={TbPlus} color="gray.500" mr={1} />
                          <Text fontSize="md" color="gray.500">
                            Add Item
                          </Text>
                        </Tag>
                      )}
                    </Box>
                  </VStack>
                  <Icon
                    as={TbPencil}
                    boxSize="16px"
                    position="absolute"
                    bottom="8px"
                    right="8px"
                    color="gray.500"
                  />
                </Box>
              </GridItem>
            </Grid>

            {/* Campaign Description */}
            <Box mt={4} p={4}>
              <Text fontSize="14px" fontWeight="700" opacity={0.75}>
                About Offer
              </Text>
              <Text fontSize="14px" mt={2}>
                {offer?.description || "No description provided."}
              </Text>
            </Box>
          </VStack>
        </Box>
      </Flex>

      {/* Sticky Button */}
      <Box
        position="sticky"
        bottom="0"
        width="100%"
        p={4}
        textAlign="center"
        zIndex={1000}
      >
        <Button
          bg="black"
          color="white"
          size="lg"
          h="60px"
          w="full"
          borderRadius="full"
          fontSize="18px"
          maxW="375px"
          onClick={handleButtonClick}
          isDisabled={isButtonDisabled && hasChanges}
          isLoading={isLoading}
          _hover={{ bg: "black" }}
          _active={{ bg: "black" }}
        >
          {hasChanges
            ? "Submit Counter Offer"
            : existingOfferAmount !== null
            ? "Accept Offer"
            : "Edit Offer"}
        </Button>
      </Box>

      {/* Edit Offer Modal */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="sm">
        <ModalOverlay />
        <ModalContent
          borderRadius="24px"
          pt="24px"
          pb="24px"
          px="24px"
        >
          <ModalHeader
            p="0"
            mb="16px"
            textAlign="center"
          >
            <ModalCloseButton
              position="absolute"
              top="8px"
              right="8px"
              color="#A7AAB0"
              _hover={{ color: "#70757D" }}
            />
            <Heading fontSize="28px" fontWeight="700" lineHeight="34px">
              Edit Offer
            </Heading>
          </ModalHeader>
          <ModalBody p="0">
            <VStack spacing={6} align="center">
              <FormControl id="offerAmount" isRequired>
                <FormLabel
                  textAlign="center"
                  fontSize="16px"
                  fontWeight="500"
                  color="#51555C"
                >
                  Offer Amount ($)
                </FormLabel>
                <Box display="flex" justifyContent="center">
                  <InputGroup width="auto">
                    <InputLeftElement
                      pointerEvents="none"
                      color="gray.400"
                      fontSize="1.5em"
                      children="$"
                    />
                    <Input
                      type="number"
                      value={offerAmount}
                      onChange={(e) => setOfferAmount(Number(e.target.value))}
                      placeholder="Enter new offer amount"
                      fontSize="2xl"
                      textAlign="center"
                      pl="1.5em"
                      width="200px"
                      min={0}
                    />
                  </InputGroup>
                </Box>
              </FormControl>

              <FormControl id="deliverables">
                <FormLabel
                  textAlign="center"
                  fontSize="16px"
                  fontWeight="500"
                  color="#51555C"
                >
                  Deliverables
                </FormLabel>
                <Box textAlign="center">
                  <Deliverables
                    initialDeliverables={deliverables}
                    onChange={(updatedDeliverables) =>
                      setDeliverables(updatedDeliverables)
                    }
                    isEditable={true}
                  />
                </Box>
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter p="0" mt="24px">
            <Button
              bg="black"
              color="white"
              size="lg"
              h="60px"
              w="full"
              borderRadius="full"
              fontSize="18px"
              onClick={() => {
                onClose();
                setHasChanges(true);
              }}
              _hover={{ bg: "black" }}
              _active={{ bg: "black" }}
            >
              Submit Offer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Contacted;
