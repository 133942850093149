// Tracking.js
import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  VStack,
  Flex,
  Button,
  HStack,
  Heading,
  Image,
  Link,
  Icon,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { TbArrowUpRight } from "react-icons/tb";
import placeholderImage from "../../../assets/placeholder.jpg";
import TrackingScan from "./TrackingScan";
import TrackingInfo from "./TrackingInfo";
import instagrampost from "../../../assets/instagrampost.png";
import instagramreel from "../../../assets/instagramreel.png";
import instagramstory from "../../../assets/instagramstory.png";
import tiktokIcon from "../../../assets/tiktok.png";

const getIconForDeliverable = (type) => {
  const lowerType = type.toLowerCase();
  if (lowerType.includes("post")) return instagrampost;
  if (lowerType.includes("story")) return instagramstory;
  if (lowerType.includes("reel")) return instagramreel;
  if (lowerType.includes("video")) return tiktokIcon;
  return instagrampost; // default icon
};

const Tracking = ({ offerData }) => {
  const { creator, campaignDetails } = offerData;
  const { offer, imageURL, listeningKeywords } = campaignDetails;
  const { deliverables: initialDeliverables, offer: initialOfferAmount } =
    creator;

  const [deliverables] = useState(initialDeliverables || []);
  const [imageSrc, setImageSrc] = useState(placeholderImage);
  const [showScan, setShowScan] = useState(false);

  // Style constants
  const bgColorCampaignInfo = "#D5D1FA";
  const bgColorOfferAmount = "#FBECC0";
  const borderRadius = "24px";

  // Scroll to top when component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (imageURL) {
      const img = new window.Image();
      img.onload = () => setImageSrc(imageURL);
      img.src = imageURL;
    }
  }, [imageURL]);

  return (
    <Box position="relative" minH="100vh" bg="white" borderRadius="24px">
      {showScan ? (
        <TrackingScan offerData={offerData} onBack={() => setShowScan(false)} />
      ) : (
        <>
          {/* Status Bar Placeholder */}
          <Box
            height="53px"
            width="100%"
            position="absolute"
            top="0"
            left="0"
            display="flex"
            justifyContent="center"
            alignItems="center"
            bg="white"
            borderTopRadius="24px"
            zIndex="1000"
          >
            {/* Add status bar content here if needed */}
          </Box>

          <Flex p={4} direction="column" align="center" mt="53px">
            <Box maxW="375px" width="100%">
              <VStack align="start" spacing={4}>
                {/* Hi {name} greeting */}
                <Text
                  fontSize="14px"
                  fontWeight="700"
                  opacity={0.75}
                  mt={4}
                  ml={1}
                >
                  Hi {creator?.name || "Creator"} 👋
                </Text>
                <Heading fontSize="28px" lineHeight="34px">
                  Now it's time to post!
                </Heading>

                {/* Grid with Campaign Info, Offer Amount, and Deliverables */}
                <Grid
                  templateColumns="repeat(2, 1fr)"
                  templateRows="repeat(2, auto)"
                  gap={2}
                  w="100%"
                >
                  {/* Campaign Info */}
                  <GridItem rowSpan={2} colSpan={1}>
                    <Link
                      href={offer?.website || "#"}
                      isExternal
                      _hover={{ textDecoration: "none" }}
                    >
                      <Box
                        borderRadius={borderRadius}
                        p={4}
                        bg={bgColorCampaignInfo}
                        height="100%"
                        position="relative"
                      >
                        <VStack
                          justifyContent="space-between"
                          align="start"
                          height="100%"
                          spacing={4}
                        >
                          <HStack justifyContent="space-between" w="100%">
                            <Image
                              src={imageSrc}
                              alt="Campaign Image"
                              borderRadius="full"
                              objectFit="cover"
                              boxSize="40px"
                              bg="white"
                              border="2px solid white"
                            />
                            <Icon as={TbArrowUpRight} boxSize="24px" />
                          </HStack>
                          <Heading
                            fontSize="24px"
                            fontWeight="700"
                            textAlign="left"
                          >
                            {offer?.name || "Offer Name"}
                          </Heading>
                          <Box w="100%">
                            <Text
                              fontSize="16px"
                              color="black"
                              textAlign="left"
                            >
                              Hosted by {offer?.companyName || "Company Name"}
                            </Text>
                          </Box>
                        </VStack>
                      </Box>
                    </Link>
                  </GridItem>

                  {/* Offer Amount */}
                  <GridItem colSpan={1}>
                    <Box
                      borderRadius={borderRadius}
                      p={4}
                      bg={bgColorOfferAmount}
                      minH="98px"
                      position="relative"
                    >
                      <VStack align="start" spacing={2}>
                        <Text fontSize="16px">Offer Amount</Text>
                        <HStack spacing={2}>
                          <Text fontSize="36px">💸</Text>
                          <Text fontSize="16px" fontWeight="700">
                            $
                            {initialOfferAmount > 0
                              ? initialOfferAmount.toLocaleString()
                              : "—"}
                          </Text>
                        </HStack>
                      </VStack>
                    </Box>
                  </GridItem>

                  {/* Deliverables */}
                  <GridItem colSpan={1}>
                    <Box
                      borderRadius={borderRadius}
                      p={4}
                      border="1px solid #C9CCD1"
                      minH="140px"
                      position="relative"
                    >
                      <VStack align="start" spacing={2}>
                        <Text fontSize="16px">Deliverables</Text>
                        <Box>
                          {deliverables && deliverables.length > 0 ? (
                            deliverables.map((item, index) => {
                              const displayType = item.type
                                .replace("Instagram ", "")
                                .replace("TikTok ", "");
                              const iconSrc = getIconForDeliverable(displayType);
                              return (
                                <HStack
                                  key={index}
                                  spacing={2}
                                  alignItems="center"
                                  mt={2}
                                >
                                  <Image
                                    src={iconSrc}
                                    boxSize="24px"
                                    alt={displayType}
                                  />
                                  <Text fontSize="16px" fontWeight="700">
                                    {item.count} {displayType}
                                    {item.count > 1 ? "s" : ""}
                                  </Text>
                                </HStack>
                              );
                            })
                          ) : (
                            <Text>No deliverables specified.</Text>
                          )}
                        </Box>
                      </VStack>
                    </Box>
                  </GridItem>
                </Grid>

                {/* Tracking Info Component */}
                <TrackingInfo
                  listeningKeywords={listeningKeywords}
                  requirements={offer?.requirements}
                  exampleMedia={offer?.exampleMedia}
                />
              </VStack>
            </Box>
          </Flex>

          {/* Submit Media Button */}
          <Box
            position="sticky"
            bottom="0"
            width="100%"
            p={4}
            textAlign="center"
            zIndex={1000}
          >
            <Button
              onClick={() => setShowScan(true)}
              bg="black"
              color="white"
              size="lg"
              h="60px"
              borderRadius="full"
              fontSize="18px"
              maxW="375px"
              w="100%"
              _hover={{ bg: "black" }}
              _active={{ bg: "black" }}
              _focus={{ boxShadow: "none" }}
            >
              Submit Media
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Tracking;
