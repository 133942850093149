// TrackingInfo.js
import React from "react";
import {
  Box,
  Text,
  HStack,
  VStack,
  Tag,
  SimpleGrid,
  Icon,
  useToast,
} from "@chakra-ui/react";
import { TbPhoto, TbClipboardCheck, TbCopy } from "react-icons/tb";
import MediaItem from "./MediaItem";

const CopyableTag = ({ keyword }) => {
  const toast = useToast();

  const handleCopy = () => {
    navigator.clipboard.writeText(keyword).then(() => {
      toast({
        title: "Copied to clipboard",
        description: `"${keyword}" has been copied to your clipboard.`,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    });
  };

  return (
    <Tag
      size="md"
      borderRadius="full"
      px={3}
      py={2}
      bg="#E6E7EB"
      color="#222429"
      cursor="pointer"
      onClick={handleCopy}
    >
      <HStack spacing={1}>
        <Text>{keyword}</Text>
        <Icon as={TbCopy} w={4} h={4} />
      </HStack>
    </Tag>
  );
};

const TrackingInfo = ({ listeningKeywords, requirements, exampleMedia }) => {
  return (
    <Box w="100%" mt={4}>
      {/* Required Information Heading */}
      <Text
        fontSize="20px"
        fontWeight="600"
        textTransform="lowercase"
        color="#222429"
      >
        Required Information
      </Text>

      {/* Tags & Mentions Section */}
      {listeningKeywords?.length > 0 && (
        <VStack
          align="start"
          spacing={5}
          mt={4}
          borderBottom="1px solid #E6E7EB"
          pb={4}
        >
          <Box>
            <Text
              fontSize="12px"
              fontWeight="400"
              textTransform="uppercase"
              color="#51555C"
            >
              Tags & Mentions
            </Text>
            <Text fontSize="14px" mt={2}>
              Please be sure to use these tags and mentions in your post:
            </Text>
            <HStack spacing={2} mt={2} wrap="wrap">
              {listeningKeywords.map((keyword, index) => (
                <CopyableTag key={index} keyword={keyword} />
              ))}
            </HStack>
          </Box>
        </VStack>
      )}

      {/* Video Requirements Section */}
      {requirements && (
        <VStack
          align="start"
          spacing={5}
          mt={4}
          borderBottom="1px solid #E6E7EB"
          pb={4}
        >
          <Box>
            <Text
              fontSize="12px"
              fontWeight="400"
              textTransform="uppercase"
              color="#51555C"
            >
              Video Requirements
            </Text>
            <Text fontSize="14px" mt={2} lineHeight={2.2}>
              {requirements}
            </Text>
          </Box>
        </VStack>
      )}

      {/* Example Media Section */}
      {exampleMedia?.length > 0 && (
        <VStack align="start" spacing={5} mt={4} pb={4}>
          <Box>
            <Text
              fontSize="12px"
              fontWeight="400"
              textTransform="uppercase"
              color="#51555C"
            >
              Content Inspiration
            </Text>
            <SimpleGrid columns={2} spacing={4} width="100%" mt={4}>
              {exampleMedia.map((media, index) => (
                <MediaItem key={index} media={media} />
              ))}
            </SimpleGrid>
          </Box>
        </VStack>
      )}
    </Box>
  );
};

export default TrackingInfo;
