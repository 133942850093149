// MediaItem.js
import React, { useState } from "react";
import { Box, Image } from "@chakra-ui/react";

const MediaItem = ({ media }) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const handleMediaClick = () => {
    if (media.content_url?.endsWith(".mp4")) setIsVideoPlaying(true);
  };

  const mediaStyles = {
    borderRadius: "xl",
    objectFit: "cover",
    width: "100%",
    height: "100%",
  };

  return (
    <Box w="100%" onClick={handleMediaClick} cursor="pointer">
      {!isVideoPlaying ? (
        <Image
          src={media.thumbnail_url}
          alt="Media Thumbnail"
          {...mediaStyles}
        />
      ) : (
        <Box as="video" controls {...mediaStyles}>
          <source src={media.content_url} type="video/mp4" />
          Your browser does not support the video tag.
        </Box>
      )}
    </Box>
  );
};

export default MediaItem;
