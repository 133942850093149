// None.js
import React from 'react';
import { Box, Text } from '@chakra-ui/react';

function None() {
  return (
    <Box
      height="80vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
    >
      <Text fontSize="2xl" fontWeight="bold">
        Oh No!
      </Text>
      <Text fontSize="lg" mt={2}>
        There is no offer currently available for you!
      </Text>
    </Box>
  );
}

export default None;
