import React from "react";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Offer from "./components/Offer";
import NotFound from "./components/NotFound";
import Header from "./components/offer/Header";

const theme = extendTheme({
  fonts: {
    heading: "Poppins, sans-serif",
    body: "Poppins, sans-serif",
  },
  colors: {
    blue: {
      300: "#b0e6eb",
      400: "#66cbd4",
      500: "#5cb5bd",
      600: "#44939a",
    },
  },
});

function App() {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/:workspaceId/:campaignId/:creatorId"
            element={<Offer />}
          />
          <Route
            path="*"
            element={
              <>
                <Header />
                <NotFound />
              </>
            }
          />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
